import React from "react";
import Layout from "../components/common/Layout";
import HeroSection from "../components/common/HeroSection";
import ProjectLogos from "../components/home/ProjectLogos";
import FormCon from "../components/Form/FormCon";
import FieldArray from "../components/Form/FieldArray";
import LoadBtn from "../components/Form/LoadBtn";

const contactForm = {
  inputs: {
    type: {
      name: "type",
      type: "select",
      options: [
        {
          title: "Owner",
          value: "Owner"
        },
        {
          title: "Mediator",
          value: "Mediator"
        }
      ]
    },
    location: {
      name: "location",
      type: "text",
      placeholder: "Location"
    },
    reference: {
      name: "reference",
      type: "text",
      placeholder: "Reference"
    },
    property_size: {
      name: "Property size",
      type: "text",
      placeholder: "Reference"
    },
    name: {
      name: "name",
      type: "text",
      placeholder: "Name"
    },
    email: {
      name: "email",
      type: "email",
      placeholder: "Email"
    },
    number: {
      name: "number",
      type: "tel",
      placeholder: "Phone number"
    }
  },
  allIds: [
    "type",
    "location",
    "reference",
    "property_size",
    "name",
    "email",
    "number"
  ],
  values: { type: "Owner" }
};

function JointVenture() {
  return (
    <Layout>
      <HeroSection title="Joint Venture" />
      <section className="residence-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 text-justify">
              <div className="pb-4">
                <p className="text-lg">
                  Baashyaam group is a highly trustworthy and a committed real
                  estate developer, based in Chennai. We are planning to widen
                  our horizon by promoting joint ventures. Do you have 2 grounds
                  of land or more? You are welcome for a joint venture with a
                  reliable builder. You can hold the land even during the
                  construction of the project. We promise high-quality
                  construction, strong adherence to safety standards and seismic
                  protection for every home.
                </p>
                <p>
                  We offer legitimate transactions with clean documentation, in
                  addition to the accruing profit. A joint venture with
                  Baashyaam group is a profitable prospect, which would give you
                  safety and security.
                </p>
              </div>
              <div className="pb-4">
                <ProjectLogos conClass="section-pad" />
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="mb-3 text-center font-weight-bold">Join Us</h4>
                  <FormCon
                    resetOnSuccess
                    form={contactForm}
                    config={{
                      url: "bashyam/",
                      method: "POST"
                    }}
                    renderForm={({ fetching }) => (
                      <>
                        <FieldArray />
                        <div className="form-group">
                          <LoadBtn
                            fetching={fetching}
                            className="btn btn-block btn-logo-color"
                          >
                            Submit
                          </LoadBtn>
                        </div>
                      </>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default JointVenture;
